<template>
    <div :class="$style.footer">
      <hr>
      <p>Copyright 2024 ⓒLinkook. All rights reserved.</p>
    </div>
</template>
  
  <script>
  export default {
    name: 'LinkookLiveFooter',
    data() {
    return {
    };
  },
  methods :{
  }
  }
  </script>
  <style module>
  .footer {
    font-family: "Noto Sans KR", sans-serif;
    background-color: white;
    font-size : 10px;
    margin-top: auto;
  }
  p {
    margin: 0px;
    text-align: left;
  }

  </style>
  