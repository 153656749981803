import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";


const firebaseConfig = {
    apiKey: "AIzaSyCpU091zo_zEd6QRjFLppNurYqGOdYn2wM",
    authDomain: "linkook-live.firebaseapp.com",
    projectId: "linkook-live",
    databaseURL: "https://linkook-live-default-rtdb.asia-southeast1.firebasedatabase.app", 
    storageBucket: "linkook-live.firebasestorage.app",
    messagingSenderId: "434887247302",
    appId: "1:434887247302:web:745ba0b2fd221a60e85ec7",
    measurementId: "G-77PMZLPQ0X"
  };
  
  const app = initializeApp(firebaseConfig);

// Firestore 초기화
const rtdb = getDatabase(app);
const firestore = getFirestore(app);

export { rtdb, firestore };
