<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: 'Malgun';
  src: url('/Users/kyobodts/Desktop/linkook/linkook-live/src/assets/malgun.ttf') format('truetype');
  /* src: url('/linkook-live/src/assets/malgun.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
}

body { 
  width : 100%;
  margin : 0px;
  font-family: var(--font-malgun);
}

::-webkit-scrollbar {
  display: none;
}

:root {
  /* fonts */
  --font-malgun: 'Malgun';
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.noto-sans-kr-default {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.noto-sans-kr-bold {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}
</style>
