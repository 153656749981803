<template>
    <div :class="$style.banner"> 
      <p :class="$style.dayCount">{{ dayCount }}</p>

      <a :href="bannerLive.link" target="_blank">
      <img :class="$style.bannerImg" :src="bannerLive.img">
      </a>
      <p :class="$style.liveDate">{{ bannerLive.date }}</p>
      <p :class="$style.livePlace">{{ bannerLive.place }}</p>
    </div>
</template>
  
  <script>
  import { parse } from 'date-fns';

  export default {
    name: 'LinkookLiveBanner',
    computed : {
      dayCount() {
      return this.calculateDaysLeft(this.bannerLive.date);
    },
    },
    data() {
    return {
      bannerLive:
        {
          title: '평범한 일상에 한스푼',
          img: require('@/assets/images/hanspoon_banner.png'),
          date: '2024.12.14 14:00',
          place: '홍대 H-STAGE',
          link: 'https://x.com/Han__Spoon',
          type: 'vocal'
        }
    };
  },
  methods: {
    calculateDaysLeft(targetDate) {
      const substrDate = targetDate.substring(0,10);
      if (!substrDate) return 'Invalid date';
      const now = new Date();
      const eventDate = parse(substrDate, 'yyyy.MM.dd', new Date()); // 파싱
        if (isNaN(eventDate.getTime())) return 'Invalid date';

        const nowMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const eventMidnight = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

        const daysLeft = Math.ceil((eventMidnight - nowMidnight) / (1000 * 60 * 60 * 24));
        return daysLeft === 0 ? 'D-DAY' : daysLeft >= 0 ? `D-${daysLeft}` : `종료`;
    }
  }
}
  </script>
  <style module>
  .banner {
    width: 100%;
    position: relative; /* 자식 요소를 배치하기 위해 relative 설정 */
    display: inline-block; /* 이미지를 감싸기 위한 적절한 레이아웃 */
  }
  .bannerImg {
    width: 100%;
  }
  .dayCount {
    position: absolute;
    top: 5px; /* 이미지 위에서의 위치 */
    left: 5px; /* 이미지 왼쪽에서의 위치 */
    color: white; /* 글자 색 */
    font-size: 1.5rem; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
    background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
    border-radius: 5px; /* 배경의 모서리를 둥글게 */
  }
  .liveDate {
    position: absolute;
    bottom: 10px; /* 이미지 위에서의 위치 */
    right: 5px; /* 이미지 왼쪽에서의 위치 */
    color: white; /* 글자 색 */
    font-size: 1rem; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
     background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
    border-radius: 5px; /* 배경의 모서리를 둥글게 */
  }
  .livePlace {
    position: absolute;
    bottom: 45px; /* 이미지 위에서의 위치 */
    right: 5px; /* 이미지 왼쪽에서의 위치 */
    color: white; /* 글자 색 */
    font-size: 1rem; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
     background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
    padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
    border-radius: 5px; /* 배경의 모서리를 둥글게 */
  }

  @media screen and (max-width: 800px) {

  .liveDate {
    font-size : 0.9rem;
    padding: 5px;
    display: none;
  }
  .livePlace {
    font-size : 0.9rem;
    padding: 5px;
    display: none;
  }
  }

  @media screen and (max-width: 330px) {
  .liveDate {
    font-size : 0.7rem;
    padding: 3px 5px; /* 배경과 글씨 사이의 여백 */
    display: none;
  }
  .livePlace {
    bottom: 32px; /* 이미지 위에서의 위치 */
    padding: 3px 5px; /* 배경과 글씨 사이의 여백 */
    font-size : 0.7rem;
    display: none;
  }
  }
  </style>
  