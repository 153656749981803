<template>
  <div :class="$style.searchBar">
    <div :class="$style.searchBox">
      <input :class="$style.searchInput"
            v-model="searchQuery"
            type="text"
            placeholder="제목으로 검색하기"/>
        <div>
          <img :class="$style.searchIcon" alt="" src="@/assets/images/search.svg" />
        </div>
    </div>
    <img
          :class="$style.plusIcon"
          loading="lazy"
          alt=""
          src="@/assets/images/plus.svg"
        />
  </div>
    <div :class="$style.tiles">
      <div :class="$style.tileItem"  v-for="(live, index) in filteredItems" :key="index">
          <a :href="live.link" target="_blank">
          <div :class="$style.imgDiv">
            <p :class="calculateDaysLeft(live.date) === '종료' ? $style.closed: $style.dayCount">{{ calculateDaysLeft(live.date) }}</p>
            <img
              loading="lazy"
              :alt="live.title"
              :src="live.img"
            />
          </div>
        </a>
        <div :class="$style.title">{{ live.title }}</div>
        <div :class="$style.title">{{ live.date }}</div>
      </div>
    </div>
</template>
  
  <script>
  import { collection, addDoc, getDocs } from "firebase/firestore";
  import { firestore } from "/firebaseConfig";
  import { parse } from 'date-fns';

  export default {
    name: 'LinkookLiveTiles',
    computed: {
      filteredItems() {
        const query = this.searchQuery.toLowerCase();
        const now = new Date();
        // 현재 날짜를 0시 기준으로 설정
        const nowMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        return this.lives
            .filter((item) =>
                item.title.toLowerCase().includes(query)
            )
            .sort((a, b) => {
                // a.date와 b.date를 파싱하여 날짜 객체로 변환
                const dateA = parse(a.date.substring(0, 10), 'yyyy.MM.dd', new Date());
                const dateB = parse(b.date.substring(0, 10), 'yyyy.MM.dd', new Date());

                const isPastA = dateA < nowMidnight; // a가 이미 지난 날짜인지 확인
                const isPastB = dateB < nowMidnight; // b가 이미 지난 날짜인지 확인

                if (isPastA && !isPastB) return 1; // a가 지난 날짜면 뒤로 이동
                if (!isPastA && isPastB) return -1; // b가 지난 날짜면 뒤로 이동
                if (dateA > dateB) return 1; // a가 b보다 미래라면 뒤로
                if (dateA < dateB) return -1; // a가 b보다 과거라면 앞으로
                return 0; // 같은 날짜라면 순서 유지
            });
    }
    },
    created() {
      this.fetchLives();
    },
    data() {
    return {
      searchQuery:"",
      lives: [
      ],
      // uploads: [
      //   {
      //     title: 'ONE OK LIVE',
      //     img: 'oneoklive.png',
      //     date: '2024.11.30 17:00',
      //     place: '홍대 롤러코스터홀',
      //     lineup: ['레드슈어','청린','KATE','M.I.A.VV','지라이밴도','TWO OK ROCK','제경','Ballantines'],
      //     link: 'https://x.com/1OKROCKLIVE',
      //     type: 'band'
      //   }
    };
  },
  methods :{
    calculateDaysLeft(targetDate) {
      const substrDate = targetDate.substring(0,10);
      if (!substrDate) return 'Invalid date';
      const now = new Date();
      const eventDate = parse(substrDate, 'yyyy.MM.dd', new Date()); // 파싱
        if (isNaN(eventDate.getTime())) return 'Invalid date';

        const nowMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const eventMidnight = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());

        const daysLeft = Math.ceil((eventMidnight - nowMidnight) / (1000 * 60 * 60 * 24));
        return daysLeft === 0 ? 'D-DAY' : daysLeft >= 0 ? `D-${daysLeft}` : `종료`;
    },
    async uploadToFirestore() {
      try {
        const collectionRef = collection(firestore, "lives"); // 'lives' collection 참조

        for (const item of this.uploads) {
          await addDoc(collectionRef, item); // Firestore에 개별 데이터 추가
        }

        console.log("All data uploaded successfully!");
      } catch (error) {
        console.error("Error uploading data:", error);
      }
    },
    async fetchLives() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "lives")); // Firestore의 'lives' 컬렉션 가져오기
        const livesData = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // 'img' 경로를 require로 변환
          const transformedData = {
            ...data,
            img: require(`@/assets/images/${data.img}`),
          };
          livesData.push(transformedData);
        });
        this.lives = livesData; // Vue 컴포넌트의 'lives' 데이터에 저장
        console.log("Lives fetched and transformed:", this.lives);
      } catch (error) {
        console.error("Error fetching lives:", error);
      }
    },
  }
}
  </script>
  <style module>

.searchBar {
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: space-between;
  }
  .searchInput {
    margin: 5px 2px;
    border : 1px solid black;
    border-radius: 20px;
    height: 30px;
    width : 400px;
    padding: 0px 10px;
    font-size: 17px;
  }

  .searchBox {
    display: inline-flex;
    background-color: var(--color-white);
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-xl);
  }

  .searchIcon{
    width: 30px;
    padding-left: 5px;
    padding-top: 5px;
  }
  .plusIcon{
    display: none;
    width: 24px;
  }
  .tiles {
    padding-top: 5px;
    padding-bottom: 10px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap; /* 줄바꿈 허용 */
    gap: 7px; /* 아이템 간격 */
    justify-content: start; /* 좌우 균등 분배 */
  }
  .tileItem {
    flex: 0 1 calc(33.333% - 5px); /* 3개씩 배치 */
    box-sizing: border-box; /* 패딩 포함 크기 계산 */
  }
  .tileItem img {
    width: 100%; /* 부모의 넓이에 맞게 확장 */
    height: auto; /* 비율 유지 */
    display: block; /* 블록 요소로 설정 */
  }
  .title{
    font-family: "Noto Sans KR", sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: 20px;
  }
  .imgDiv{
    position: relative; /* 자식 요소를 배치하기 위해 relative 설정 */
    display: inline-block; /* 이미지를 감싸기 위한 적절한 레이아웃 */
  }
  .dayCount {
    position: absolute;
    top: 5px; /* 이미지 위에서의 위치 */
    right: 5px; /* 이미지 왼쪽에서의 위치 */
    color: black; /* 글자 색 */
    font-size: 0.8rem; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
    background-color: rgba(255, 255, 255, 1); /* 반투명 배경 */
    padding: 0px 5px; /* 배경과 글씨 사이의 여백 */
    border-radius: 5px; /* 배경의 모서리를 둥글게 */
  }
  .closed {
    position: absolute;
    top: 5px; /* 이미지 위에서의 위치 */
    right: 5px; /* 이미지 왼쪽에서의 위치 */
    color: grey; /* 글자 색 */
    font-size: 0.8rem; /* 글자 크기 */
    font-weight: bold; /* 글자 두께 */
    background-color: rgba(255, 255, 255, 1); /* 반투명 배경 */
    padding: 0px 5px; /* 배경과 글씨 사이의 여백 */
    border-radius: 5px; /* 배경의 모서리를 둥글게 */
  }
  a {
    padding: 0px;
  }

  @media screen and (max-width: 1200px) {
    .title {
      font-size: 17px;
    }
    .searchInput {
      height: 25px;
      width : 300px;
      font-size: 16px;
    }
    .searchIcon{
      width: 20px;
    }
  }


  @media screen and (max-width: 1000px) {
    .title {
      font-size: 15px;
    }
    .searchInput {
      height: 25px;
      width : 300px;
      font-size: 14px;
    }
    .searchIcon{
      width: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    .title {
      font-size: 14px;
    }
    .searchInput {
      height: 20px;
      width : 200px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 700px) {
    .title {
      font-size: 12px;
    }
    .searchInput {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 540px) {
    .title {
      font-size: 10x;
    }
  }
  @media screen and (max-width: 430px) {
    .title {
      font-size: 9px;
    }
  }
  @media screen and (max-width: 330px) {
    .title {
      font-size: 8px;
    }
  }
  @media screen and (max-width: 290px) {
    .title {
      font-size: 7px;
    }
    .searchInput {
      width : 150px;
    }
  }
  </style>
  